
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$STSL_VisitorManager_AdminWebClient-primary: mat.define-palette(mat.$indigo-palette, 700, 600, 900);
$STSL_VisitorManager_AdminWebClient-accent: mat.define-palette(mat.$blue-palette, 700, 600, 900);

// The warn palette is optional (defaults to red).
$STSL_VisitorManager_AdminWebClient-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$STSL_VisitorManager_AdminWebClient-theme: mat.define-light-theme((
  color: (
    primary: $STSL_VisitorManager_AdminWebClient-primary,
    accent: $STSL_VisitorManager_AdminWebClient-accent,
    warn: $STSL_VisitorManager_AdminWebClient-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($STSL_VisitorManager_AdminWebClient-theme);

// Fix for alignment of Material buttons with Material inputs inside flex containers
.flex {
  > .align-button-with-input {
    padding-bottom: 1.34375em;

    > .mat-mdc-button-base {
      width: 100%;
      height: max(calc(36px + .4em), 100%);
    }
  }
}

.mat-mdc-tab-group,
.mat-mdc-tab-body-wrapper {
  /* height: 100% !important; */
  flex-grow: 1;
}

.mat-mdc-tab-body {
  background-color: white;
}

.mat-sort-header-content {
  text-align: unset !important;
}
